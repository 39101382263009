import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/LiveStreamPerm';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Live_Video/DangerBox';
export const _frontmatter = {
  "title": "Live Video",
  "path": "/Web_User_Interface/1440p_Series/Live_Video/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Live Video, Pan&Tilt Control, Quick-Access Tiles and SD Card Viewer.",
  "image": "./WebUI_1440p_SearchThumb_System_WebUI.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_WebUI.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Live Video' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Live Video, Pan&Tilt Control, Quick-Access Tiles and SD Card Viewer.' image='/images/Search/QI_SearchThumb_WebUI_1440p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Live_Video/' locationFR='/fr/Web_User_Interface/1440p_Series/Live_Video/' crumbLabel="Live Video" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#live-video",
        "aria-label": "live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Live Video`}</h2>
    <p>{`When you access your camera's web user interface, you will be greeted with the live video window. Please refer to our `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Live_Video/"
      }}>{`Plugin Guide`}</a>{` if you cannot see the video stream.`}</p>
    <h3 {...{
      "id": "settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#settings",
        "aria-label": "settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Settings`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c406266c3213b0d7921f699dc30b04a/9239a/1440p_Settings_Live_Video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC/ElEQVQoz1XRzU+bBQDH8WdGh0M3xW3iSNko6wvt6Ovz2vf26fO0pRTCWKEFaQm0OAyso7hCpmQ6aDbjNrNE4l7EOQ9LlhgvevNg4sGTXjTTg/Hmxb+iX9Ps5O13+uSX30/4o7eXZz09nT8PHeIXQSCtagQiUSQthF8NMSpr+FQNryTjlRQCkopfUvDLKg6Pj4tr61QWFxEVhXAsjnDD7WbPOdLZs9m5dtaGz+rCP+zD8EeppUw+LGTYmrxAfbxMa/USH91o8051mWIyTymZY2l8mkJYx3fagcc5iqAVJlDyhY6Yy+Mzs9RyY8wrJsVAgU+n5nhcKfNtY537G3t8//k99j9p8PX2Kl9tbLJbepvL2RmaqQwVj4+Y3YVgZrLoabOTTKWJJ3XykTRlxWTnwjUe1e9xZ+Uq+7UKW5PTXCmX2CtP8c12ne+uX+fhpZvcKb7LwcJ5tuMak3IUIZ0dI6YbnZhuEE+bJFxh1tIFvtxqc2X2NvXCMo2ZJS7Pr9Kaq7A7X2WnuEJ7tsbH1ToHCzWWc3UCrghhJYagZ3NdqBPVDZJGhtmIhuPEAD6Lg3JA5GJcpjjXYH5qgVZ1kdmUwWZ2gn/ut/mpGePZQZUnd3+gOTVPwut/DiYMs9Ntp8VTfLais5LXyXpHWVbdlIJuMqkcbucoweFhUp4gT9cy/NyO8eOik782z/JvO8Lf74e5NS0jGGN5kmamkzBMpGiKnOjgwc4GZT3BUtzHrYUQM5Kd117v43T/ABMJjZ1mmci5AMrgCGXxLe6e7+P3Si9flEYQus26G8bTBom0weFX3mA3O8DTq1l+e1zi1w+cpByDnLGcYjyaoLnd4lw8jvWUFdewneNHj/PSC8cwBo/wnmZB6J7xHDRJGiZHjvZRsB/mUUvnSbWfddGD32rDHDlJY22V/dttbAMWhixDDFltnDj5Jq/2vsyxnheJnOn/P9jNohoiFAkRkBREWSWoRAhKGsGgiBKO4ldCeIMykqohhmLI0QRyOIYUjhJQw/wHF5idyaE0hfsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c406266c3213b0d7921f699dc30b04a/e4706/1440p_Settings_Live_Video.avif 230w", "/en/static/6c406266c3213b0d7921f699dc30b04a/d1af7/1440p_Settings_Live_Video.avif 460w", "/en/static/6c406266c3213b0d7921f699dc30b04a/7f308/1440p_Settings_Live_Video.avif 920w", "/en/static/6c406266c3213b0d7921f699dc30b04a/35144/1440p_Settings_Live_Video.avif 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c406266c3213b0d7921f699dc30b04a/a0b58/1440p_Settings_Live_Video.webp 230w", "/en/static/6c406266c3213b0d7921f699dc30b04a/bc10c/1440p_Settings_Live_Video.webp 460w", "/en/static/6c406266c3213b0d7921f699dc30b04a/966d8/1440p_Settings_Live_Video.webp 920w", "/en/static/6c406266c3213b0d7921f699dc30b04a/0d9bd/1440p_Settings_Live_Video.webp 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c406266c3213b0d7921f699dc30b04a/81c8e/1440p_Settings_Live_Video.png 230w", "/en/static/6c406266c3213b0d7921f699dc30b04a/08a84/1440p_Settings_Live_Video.png 460w", "/en/static/6c406266c3213b0d7921f699dc30b04a/c0255/1440p_Settings_Live_Video.png 920w", "/en/static/6c406266c3213b0d7921f699dc30b04a/9239a/1440p_Settings_Live_Video.png 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c406266c3213b0d7921f699dc30b04a/c0255/1440p_Settings_Live_Video.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`Settings`}</strong>{` in the top right to open the settings menu. The settings menu contains all the settings that are described in our `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Overview/"
      }}>{`Web User Interface Guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "ptz",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ptz",
        "aria-label": "ptz permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5df9187229f008ea476e214357abce02/9239a/1440p_View_Relay.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC7klEQVQozyWQ209bBQDGz5JNQMOD6xiy0vuF0wuH9bQ9PW1PL3Nt4bDSldLRi73AaOVSQtyKKWxzQafgJMyFGZcsxhijxCdN9icY33w0/j0/Az78vsdfvu8TInGNkBq7IKCohKIxZEUloMaRozGkYJjpQBgpqFxwMxy5YCYcQQ5HCCoKgVAYOawQVqMIE+PX8dlcKB6JTChOJphme17n20Gf77455ejJU7bW62xWqnSKS1QyOrqaIil6mUqXsNT2cFX6OBuP8eSWEYTL7+Iw2tGVBI1MnrVMh+PWI043Nji63+HowS4n/RV+/+Ihrz55wt5Sh68ri3RkP/7CJs69M6KPfiT17Dd8ix8jzCajvDj4jN1Sg3wwxYudZwyaJ2wtr7FTW2WzXGNQ+Yj9WpfD9V1OVrfZyLdIBDRuZu5h0dtMlvuMto5x6C2EVjFNp3yXo+U5crJE0D3NsqrQXrhLtb5Nr15n8cMs3292+furBn8eLnJ2/BPdfJFkKEBCDeEtdok8/RW73kYQBOE8UOzXOOzV0fwSpZCX2m0NeUbGabZQiKv8sl/guBrirOnhnwOFfwcKq7kIPjmMWOzywfornOcNh4aHGRkevpD2sl76BYmXvSSnnQh2yyRel4dmq0pWz2E3+Zkym0m4DJzcGmUtIxNLpnEXOlh33mA/F9rsTqbcLgThCsrEJd4+Vnm7H6MZDSJajPTaNZ5/eYDNbGXa48NksjJhsrMQD5JXJUJaGmehi3HrNY7zyTarlRsWJx7jVcpRA4b3R6iqRv7YiFGTxlhrt5ktlXGYTIiiyPh1AzFN48GSxu17dUwrh0w2P8e0/hLnfBvhytB7RB2j5N3/f3np8jt0rOP8FRzhU89V5JzGrftFYrpGMKswNnmNGUXGbBhiPL6Ad/AzYyvPER/+gG1+FcFsd1NKiKSjPm7Y3MheLy3RR8zmxerwIAYkkgtZZkt3COc03JIft8+LY8qNL6Xjv9NA0muIc3Wk1Bz/AWovjVUONMkgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5df9187229f008ea476e214357abce02/e4706/1440p_View_Relay.avif 230w", "/en/static/5df9187229f008ea476e214357abce02/d1af7/1440p_View_Relay.avif 460w", "/en/static/5df9187229f008ea476e214357abce02/7f308/1440p_View_Relay.avif 920w", "/en/static/5df9187229f008ea476e214357abce02/35144/1440p_View_Relay.avif 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5df9187229f008ea476e214357abce02/a0b58/1440p_View_Relay.webp 230w", "/en/static/5df9187229f008ea476e214357abce02/bc10c/1440p_View_Relay.webp 460w", "/en/static/5df9187229f008ea476e214357abce02/966d8/1440p_View_Relay.webp 920w", "/en/static/5df9187229f008ea476e214357abce02/0d9bd/1440p_View_Relay.webp 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5df9187229f008ea476e214357abce02/81c8e/1440p_View_Relay.png 230w", "/en/static/5df9187229f008ea476e214357abce02/08a84/1440p_View_Relay.png 460w", "/en/static/5df9187229f008ea476e214357abce02/c0255/1440p_View_Relay.png 920w", "/en/static/5df9187229f008ea476e214357abce02/9239a/1440p_View_Relay.png 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5df9187229f008ea476e214357abce02/c0255/1440p_View_Relay.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera model has a Pan, Tilt and Zoom camera head, just hover your mouse pointer over the live image, to display the control pad on the left to pan and tilt your camera. Cameras, like the IN-9020 Full HD, additionally have a varifocal auto-zoom lense, that is zoomed and focussed by the Button array on the right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/669eb/1440p_View_PTZ.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC8UlEQVQozyXQ209bBQCA8bOLjFAuQxBYW0u7U9rTc3qhnPac0xs9tUBpuZSLpV3LfcCArd2ijttCZXO6mWCccYvZg1kWjb4aE3030X/Bf+czsoff+5dPiI+aROMp9ESKaCxGJJYgGk8Q1nSGI1GCqoo/rBIc0QiqGqGIxnBUf0czUDX9QkiNEtENBDk1j9dcYTQ9zp3CMLPFEvnpRdYLOsdTbh4uGhRnpxkbN8maJulUipiRwNBiGJEoQ/P7OCrH+FZOEGuPENo6emhp78N/U2Ypk2XJnOXBzAOer37KdztrfLG5xZNGnW8bNX5pfsbZ5icczq9yNp9jwR/Au/EUz8Fb0s236CdvEETRSXlqgpdnZ1S0HMuZAi/uf0+91KR+a5P9pTXufVzmqLzKaW2Xr3cfcr6yRyVbIqUmkXNVnJPL9JUf0bX8DCEVtLNWKnBvYZpmMcPIkJeEPEI1FqG2sEyltMVeeYli8iN+O27wz2mev863eXX6AxVzlKSmEjN0/BtNIoc/Ily90oYgCBfWMwqNWom4x01JDzJvJpE8EkMOJzvTJj8f5nleDPD7HYV/P1f5u64ykTIIRg2ct47ov/0NgnD5Cp2dHQiXLtN+rZWnS2F2JxV+PcnQyCsMWG0Y4Qjr28toehyXXUYetDHj7+X1VD9z2RSxUZOb1WNc+y8Runt68Uo+PrTbLio3tQ7+eJzkp70IphwkPGTnyckB9d0tbAN2ArLCQL+VlrYutBttjCV1RpIZBitHWHdeIAw6ndjsdqwOEcNpIa/2YLG08HhW4k01yJxsZetuHTWWwOty4RJFrndaaG15jxnxKqHyXRwbz7Ctf4lj+/z/KIGu7l4K3ktEbe9evm9p5ytXN3+GWikO9TNczBIrj6FPxJHiCn22D+jo6eL6NQFX5QDx/mtu3D7HU3+FICl+RNFNbVxBkiUcbg9pyUfVo+Bz+XC4JQK6SqaYY2KxQCitIQUDyKEAHkVBzs4RnKrhn6zgy1X4D9n/hELGFljPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/e4706/1440p_View_PTZ.avif 230w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/d1af7/1440p_View_PTZ.avif 460w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/7f308/1440p_View_PTZ.avif 920w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/2b301/1440p_View_PTZ.avif 1244w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/a0b58/1440p_View_PTZ.webp 230w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/bc10c/1440p_View_PTZ.webp 460w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/966d8/1440p_View_PTZ.webp 920w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/428d4/1440p_View_PTZ.webp 1244w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/81c8e/1440p_View_PTZ.png 230w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/08a84/1440p_View_PTZ.png 460w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/c0255/1440p_View_PTZ.png 920w", "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/669eb/1440p_View_PTZ.png 1244w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1bf0b5c7a1254d8b8cf145746f5c9243/c0255/1440p_View_PTZ.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on Positions, in the top centre of the live image, opens the menu to store camera positions and recall them later. These positions can be used as `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Features/PTZ/"
      }}>{`Alarm or Start Position`}</a>{`. They also make it much easier to control your PTZ camera with our smartphone app.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24c49cb078bd43f016c5522a0d6232e5/c9c44/1440p_View_PTZ_Save.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAe0lEQVQI1wXBuw6CMBQAUP7D0ZEE0td9NIRGKl7aysBCupg4kjj5Dep/e04jZZ1kiZLGOPsxILMBAD9o9BbJIPWOO+c7yxrIILe9+nx/tVZjbHPLJUoKVwlThEtW5Wjza430vEPaat72x6LfcjrkTOQUsLIwp4J+6LX9AyyiHnNZ34AoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24c49cb078bd43f016c5522a0d6232e5/e4706/1440p_View_PTZ_Save.avif 230w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/d1af7/1440p_View_PTZ_Save.avif 460w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/7f308/1440p_View_PTZ_Save.avif 920w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/b3f84/1440p_View_PTZ_Save.avif 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24c49cb078bd43f016c5522a0d6232e5/a0b58/1440p_View_PTZ_Save.webp 230w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/bc10c/1440p_View_PTZ_Save.webp 460w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/966d8/1440p_View_PTZ_Save.webp 920w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/2b169/1440p_View_PTZ_Save.webp 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24c49cb078bd43f016c5522a0d6232e5/81c8e/1440p_View_PTZ_Save.png 230w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/08a84/1440p_View_PTZ_Save.png 460w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/c0255/1440p_View_PTZ_Save.png 920w", "/en/static/24c49cb078bd43f016c5522a0d6232e5/c9c44/1440p_View_PTZ_Save.png 1245w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24c49cb078bd43f016c5522a0d6232e5/c0255/1440p_View_PTZ_Save.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Move your camera to a position, switch the drop-down menu to `}<strong parentName="p">{`Save Position`}</strong>{` and select a preset you want to assign it to.`}</p>
    <p>{`To use the `}<strong parentName="p">{`Scan`}</strong>{` function you can set a start and end point here. The scan will only pan the camera between those points horizontally. The height of the scan is defined by the position the camera is in when you start the scan.`}</p>
    <h3 {...{
      "id": "sd-card-viewer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sd-card-viewer",
        "aria-label": "sd card viewer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card Viewer`}</h3>
    <h4 {...{
      "id": "overview-of-your-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#overview-of-your-recordings",
        "aria-label": "overview of your recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview of your recordings`}</h4>
    <p>{`The SD-Viewer gives you the possibility to preview the images and videos. To display the preview you can simply click on the file name. You can save images and videos directly to your computer using the download button. You can also check the multiple files and download them all at once by clicking on the Save button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/56eaed8be52d79f9882c4668130bb887/9239a/1440p_View_SD_Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACqUlEQVQ4y42STW8TVxSGvWFViTrIzoed2GOnDrFDgknij4k9M565c+fOtx1AQkmQQAICVF1AS0HKquuKtnTRbf9E/0l/QFdd9F881YxNk9JNF6/OuffqPvc959zC5maTL3Z77MSvaamvqIsvqTkv57IzvVjEl2jOOQ3nKZr9hJb7jLZ3zv7x1wzuvqJSa1DX6hS0hsbW7j6j6RP0+DHD+DGD6BHDaJ5f1Z3gBbfCN3Sib+lEb9lL37CXvKIrTtlYq7JSKlPQmpvc3N7GTO9zdPwQfXrCcHpCL3lAN7ifr7vxCbojWJbvuPb6d0o//0n517/47Ps/uPbsNzT9LtuVEqul5QzYZKvdwU+PUekxXjIjmN1DhCkjoZDxDEOGjB3B3ihGnX7H+cUP/PjTB355/56Tby7YFz7razWWyysU6o0mNzs7BElKEKeoKCZMUmQQYjgCFcZYQmI4EvNWi2m7yrDTJpqYXKQ9PjzvYtqHHLRaNNfKGbCRA/0kzaWihDCd4uZAFy+KsVzJ2JEc6QOm1h7m0SF3TIv2YR/V32HYO2DQKHO7uvQ/gJlD18tzW4UYMsINE6Qf4voBYy9CtxxKxc+p3bg+LznrYXAFGPzH4QIoFUIphB/8s5e51w2LpWKR5eL1y6H8C5hcAtUV4ER6TKTC9vxFX938rG9YVJeK1Jc+AWbDUPG8ZBlElw4Xly8lMYTEzB4Rc4erKyuUPv7DDOhGM5xwhhvGuVPhh4xtkYPNT4Cm42DZE0aOQHc9BtaE9brGanWdQk3TaLV3uBcYnIV9pCewpJ+XlpXzMc6lmGQTVzMG0UOUCDkzbbyxRaVWZ3WtQmGjrtHc2mZq7XJmtzBGfQ6GI3r6ET19xOEizvMRvaFOd+zTcR5gDSacdg+w93tUtAaV9Q3+Bn5sw7mNh/MFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/e4706/1440p_View_SD_Card.avif 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/d1af7/1440p_View_SD_Card.avif 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/7f308/1440p_View_SD_Card.avif 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/35144/1440p_View_SD_Card.avif 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/a0b58/1440p_View_SD_Card.webp 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/bc10c/1440p_View_SD_Card.webp 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/966d8/1440p_View_SD_Card.webp 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/0d9bd/1440p_View_SD_Card.webp 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/81c8e/1440p_View_SD_Card.png 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/08a84/1440p_View_SD_Card.png 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/c0255/1440p_View_SD_Card.png 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/9239a/1440p_View_SD_Card.png 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/56eaed8be52d79f9882c4668130bb887/c0255/1440p_View_SD_Card.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "previewing-h265-video-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#previewing-h265-video-files",
        "aria-label": "previewing h265 video files permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Previewing h.265 Video Files`}</h4>
    <p>{`Your camera, by default, records alarm videos in full resolution with the h.265 video compression. But `}<a parentName="p" {...{
        "href": "https://caniuse.com/?search=hevc"
      }}>{`currently only the Apple Safari Browser and the Microsoft Edge Browser (needs to be activated first!)`}</a>{` support the playback of h.265 files. You will not be able to preview your recordings in other browsers like Firefox, Chrome, etc. You can `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`change the resolution to 1080p`}</a>{` in the alarm action settings to also change the video compression to h.264. You should be able to use the preview feature with most browsers after the change.`}</p>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/56eaed8be52d79f9882c4668130bb887/9239a/1440p_View_SD_Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACqUlEQVQ4y42STW8TVxSGvWFViTrIzoed2GOnDrFDgknij4k9M565c+fOtx1AQkmQQAICVF1AS0HKquuKtnTRbf9E/0l/QFdd9F881YxNk9JNF6/OuffqPvc959zC5maTL3Z77MSvaamvqIsvqTkv57IzvVjEl2jOOQ3nKZr9hJb7jLZ3zv7x1wzuvqJSa1DX6hS0hsbW7j6j6RP0+DHD+DGD6BHDaJ5f1Z3gBbfCN3Sib+lEb9lL37CXvKIrTtlYq7JSKlPQmpvc3N7GTO9zdPwQfXrCcHpCL3lAN7ifr7vxCbojWJbvuPb6d0o//0n517/47Ps/uPbsNzT9LtuVEqul5QzYZKvdwU+PUekxXjIjmN1DhCkjoZDxDEOGjB3B3ihGnX7H+cUP/PjTB355/56Tby7YFz7razWWyysU6o0mNzs7BElKEKeoKCZMUmQQYjgCFcZYQmI4EvNWi2m7yrDTJpqYXKQ9PjzvYtqHHLRaNNfKGbCRA/0kzaWihDCd4uZAFy+KsVzJ2JEc6QOm1h7m0SF3TIv2YR/V32HYO2DQKHO7uvQ/gJlD18tzW4UYMsINE6Qf4voBYy9CtxxKxc+p3bg+LznrYXAFGPzH4QIoFUIphB/8s5e51w2LpWKR5eL1y6H8C5hcAtUV4ER6TKTC9vxFX938rG9YVJeK1Jc+AWbDUPG8ZBlElw4Xly8lMYTEzB4Rc4erKyuUPv7DDOhGM5xwhhvGuVPhh4xtkYPNT4Cm42DZE0aOQHc9BtaE9brGanWdQk3TaLV3uBcYnIV9pCewpJ+XlpXzMc6lmGQTVzMG0UOUCDkzbbyxRaVWZ3WtQmGjrtHc2mZq7XJmtzBGfQ6GI3r6ET19xOEizvMRvaFOd+zTcR5gDSacdg+w93tUtAaV9Q3+Bn5sw7mNh/MFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/e4706/1440p_View_SD_Card.avif 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/d1af7/1440p_View_SD_Card.avif 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/7f308/1440p_View_SD_Card.avif 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/35144/1440p_View_SD_Card.avif 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/a0b58/1440p_View_SD_Card.webp 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/bc10c/1440p_View_SD_Card.webp 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/966d8/1440p_View_SD_Card.webp 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/0d9bd/1440p_View_SD_Card.webp 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/56eaed8be52d79f9882c4668130bb887/81c8e/1440p_View_SD_Card.png 230w", "/en/static/56eaed8be52d79f9882c4668130bb887/08a84/1440p_View_SD_Card.png 460w", "/en/static/56eaed8be52d79f9882c4668130bb887/c0255/1440p_View_SD_Card.png 920w", "/en/static/56eaed8be52d79f9882c4668130bb887/9239a/1440p_View_SD_Card.png 1246w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/56eaed8be52d79f9882c4668130bb887/c0255/1440p_View_SD_Card.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h4 {...{
      "id": "load-all-videos-and-images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#load-all-videos-and-images",
        "aria-label": "load all videos and images permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Load all Videos and Images`}</h4>
    <p>{`If you do not want to download each file individually, then you can use the INSTAR Camera Tool. This has an integrated downloader for the SD card of your camera. This has a built-in downloader for your camera's SD card.`}</p>
    <ol>
      <li parentName="ol">{`start the camera tool and click on the tab SD card. Then select your camera. If you have not already done so, you will be asked to authenticate yourself to the camera. Then click on `}<strong parentName="li">{`Browse`}</strong>{`. 2.`}</li>
    </ol>
    <p>{`Click on the selection box at the top left to select all folders on the SD card. Alternatively, you can select only the desired folder. Then click on Download. The download will then start and may take a few minutes depending on the amount of data.`}</p>
    <p>{`You can also access the content of the SD card directly via the web browser by appending a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/sd`}</code>{` to the IP of the camera in the address line:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/723c0c23e0e938d1b5109f717ff94b65/25260/1440p_SD_Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.782608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAVElEQVQI143CiQ2AIAwAQNYASqk8BQPyGRP3n8wR8HKizVXaWM/b58TDK0BABLR/CpeukHsst+eELqcyjCWpQYHZFkCR8xk4BGaDWGq1RFJrBbD9AV+uFlQxDZAWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/723c0c23e0e938d1b5109f717ff94b65/e4706/1440p_SD_Card.avif 230w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/d1af7/1440p_SD_Card.avif 460w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/7f308/1440p_SD_Card.avif 920w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/e3393/1440p_SD_Card.avif 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/723c0c23e0e938d1b5109f717ff94b65/a0b58/1440p_SD_Card.webp 230w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/bc10c/1440p_SD_Card.webp 460w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/966d8/1440p_SD_Card.webp 920w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/9bbd4/1440p_SD_Card.webp 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/723c0c23e0e938d1b5109f717ff94b65/81c8e/1440p_SD_Card.png 230w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/08a84/1440p_SD_Card.png 460w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/c0255/1440p_SD_Card.png 920w", "/en/static/723c0c23e0e938d1b5109f717ff94b65/25260/1440p_SD_Card.png 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/723c0c23e0e938d1b5109f717ff94b65/c0255/1440p_SD_Card.png",
              "alt": "Web User Interface - 1440p Series - Live_Video",
              "title": "Web User Interface - 1440p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      